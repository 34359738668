import { ReactElement, useContext, useEffect, useState } from "react";
import { Box, Flex, Image, Text } from "@chakra-ui/react";
import { ImageContext } from "../atoms/ImageProvider";
import { useTranslation } from "react-i18next";
import WeatherSunnyImgUrl from "../../assets/images/weather/sunny.png";
import WeatherCloudyImgUrl from "../../assets/images/weather/cloudy.png";
import WeatherRainyImgUrl from "../../assets/images/weather/rainy.png";
import { ResultDiagramIndex, ResultDiagramParam } from "../../types";
import { ResultDiagram } from "../atoms/ResultDiagram";
import { useAtom } from "jotai";
import {
  co2ClassificationThresholdHigh,
  co2ClassificationThresholdLow,
} from "../../store";
import { useAssertEngineTypeFromPathParam } from "../../utils/selectAnalysisEngine";

type ClassType = "A" | "B" | "C";

function useDetermineClass(): (score: number) => ClassType {
  const [thresholdLow] = useAtom(co2ClassificationThresholdLow);
  const [thresholdHigh] = useAtom(co2ClassificationThresholdHigh);

  return (score: number): ClassType => {
    if (score > thresholdHigh) {
      return "A";
    } else if (score > thresholdLow && score <= thresholdHigh) {
      return "B";
    } else if (score >= 0 && score <= thresholdLow) {
      return "C";
    } else {
      throw new Error("Score is out of the expected range.");
    }
  };
}

export function ResultCo2Demo(props: {
  title: string;
  pre_cog_score: number;
  co2_result: string;
  pre_cog_subscore_ah?: number;
  pre_cog_subscore_eh?: number;
  pre_cog_subscore_uh?: number;
}): ReactElement {
  const engineType = useAssertEngineTypeFromPathParam();
  const { imageSrc, preloadImage } = useContext(ImageContext);
  const [resultComment, setResultComment] = useState("");
  const { t } = useTranslation();
  const determineClass = useDetermineClass();

  const diagramIndex: ResultDiagramIndex[] = [
    {
      width: "60px",
      title: t("PJ.Co2DemoResult.Total"),
      align: "center",
    },
    {
      width: "20px",
      title: props.co2_result,
      align: "center",
    },
    {
      width: "60px",
      title: props.pre_cog_score.toFixed(2),
      align: "center",
    },
  ];

  const diagramParams: ResultDiagramParam[][] = [];
  if (props.pre_cog_subscore_ah !== undefined) {
    diagramParams.push([
      { value: t("PJ.Co2DemoResult.Phrase1") },
      {
        value: determineClass(props.pre_cog_subscore_ah),
      },
      { value: props.pre_cog_subscore_ah.toFixed(2) },
    ]);
  }
  if (props.pre_cog_subscore_eh !== undefined) {
    diagramParams.push([
      { value: t("PJ.Co2DemoResult.Phrase2") },
      {
        value: determineClass(props.pre_cog_subscore_eh),
      },
      { value: props.pre_cog_subscore_eh.toFixed(2) },
    ]);
  }
  if (props.pre_cog_subscore_uh !== undefined) {
    diagramParams.push([
      { value: t("PJ.Co2DemoResult.Phrase3") },
      {
        value: determineClass(props.pre_cog_subscore_uh),
      },
      { value: props.pre_cog_subscore_uh.toFixed(2) },
    ]);
  }

  useEffect(() => {
    if (!imageSrc || !imageSrc.WeatherImg[props.co2_result]) {
      preloadImage &&
        preloadImage("WeatherImg", {
          A: WeatherSunnyImgUrl,
          B: WeatherCloudyImgUrl,
          C: WeatherRainyImgUrl,
        });
    }
    switch (props.co2_result) {
      case "A":
        setResultComment(t("Co1DemoResult.resultA_comment"));
        break;
      case "B":
        setResultComment(t("Co1DemoResult.resultB_comment"));
        break;
      case "C":
        setResultComment(t("Co1DemoResult.resultC_comment"));
        break;
    }
  }, [setResultComment, t, imageSrc, preloadImage, props.co2_result]);
  return (
    <Box mb={10} color="#555555">
      <Flex flexDirection="column" justifyContent="center" alignItems="center">
        {engineType === "TFH" && (
          <Text fontSize="2xl">{t("TFH.Co2.Title")}</Text>
        )}
        <Text fontSize="2xl">{resultComment}</Text>
        <Image
          src={imageSrc ? imageSrc.WeatherImg[props.co2_result] : undefined}
          boxSize="80%"
        />
        <ResultDiagram
          diagramIndex={diagramIndex}
          diagramParams={diagramParams}
        />
        <Box width="80%" mt={5}>
          <Text fontSize="sm">{t("Co1DemoResult.note1")}</Text>
          <Text fontSize="sm" mt={2}>
            {t("Co1DemoResult.note2")}
          </Text>
        </Box>
      </Flex>
    </Box>
  );
}
